export default {
  soulPalace: '명궁',
  bodyPalace: '신궁',
  siblingsPalace: '형제',
  spousePalace: '부처',
  childrenPalace: '자녀',
  wealthPalace: '재백',
  healthPalace: '질액',
  surfacePalace: '천이',
  friendsPalace: '노복',
  careerPalace: '관록',
  propertyPalace: '전택',
  spiritPalace: '복덕',
  parentsPalace: '부모',
  originalPalace: '라인',
} as const;
