export default {
  soulPalace: '命宮',
  bodyPalace: '身宮',
  siblingsPalace: '兄弟',
  spousePalace: '夫妻',
  childrenPalace: '子女',
  wealthPalace: '財帛',
  healthPalace: '疾厄',
  surfacePalace: '遷移',
  friendsPalace: '僕役',
  careerPalace: '官祿',
  propertyPalace: '田宅',
  spiritPalace: '福德',
  parentsPalace: '父母',
  originalPalace: '来因',
} as const;
