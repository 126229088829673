export default {
  jiaHeavenly: 'Giáp',
  yiHeavenly: 'Ất',
  bingHeavenly: 'Bính',
  dingHeavenly: 'Đinh',
  wuHeavenly: 'Mậu',
  jiHeavenly: 'Kỷ',
  gengHeavenly: 'Canh',
  xinHeavenly: 'Tân',
  renHeavenly: 'Nhâm',
  guiHeavenly: 'Quý',
} as const;
