export default {
  soulPalace: '命宫',
  bodyPalace: '身宫',
  siblingsPalace: '兄弟',
  spousePalace: '夫妻',
  childrenPalace: '子女',
  wealthPalace: '财帛',
  healthPalace: '疾厄',
  surfacePalace: '迁移',
  friendsPalace: '仆役',
  careerPalace: '官禄',
  propertyPalace: '田宅',
  spiritPalace: '福德',
  parentsPalace: '父母',
  originalPalace: '来因',
} as const;
