export default {
  ziEarthly: '子',
  chouEarthly: '丑',
  yinEarthly: '寅',
  maoEarthly: '卯',
  chenEarthly: '辰',
  siEarthly: '巳',
  wuEarthly: '午',
  weiEarthly: '未',
  shenEarthly: '申',
  youEarthly: '酉',
  xuEarthly: '戌',
  haiEarthly: '亥',
} as const;
