export default {
  jiaHeavenly: 'jia',
  yiHeavenly: 'yi',
  bingHeavenly: 'bing',
  dingHeavenly: 'ding',
  wuHeavenly: 'wu',
  jiHeavenly: 'ji',
  gengHeavenly: 'geng',
  xinHeavenly: 'xin',
  renHeavenly: 'ren',
  guiHeavenly: 'gui',
} as const;
