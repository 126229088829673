export default {
  ziweiMaj: 'Tử Vi',
  tianjiMaj: 'Thiên Cơ',
  taiyangMaj: 'Thái Dương',
  wuquMaj: 'Vũ Khúc',
  tiantongMaj: 'Thiên Đồng',
  lianzhenMaj: 'Liêm Trinh',
  tianfuMaj: 'Thiên Phủ',
  taiyinMaj: 'Thái Âm',
  tanlangMaj: 'Tham Lang',
  jumenMaj: 'Cự Môn',
  tianxiangMaj: 'Thiên Tướng',
  tianliangMaj: 'Thiên Lương',
  qishaMaj: 'Thất Sát',
  pojunMaj: 'Phá Quân',
  zuofuMin: 'Tả Phù',
  youbiMin: 'Hữu Bật',
  wenchangMin: 'Văn Xương',
  wenquMin: 'Văn Khúc',
  lucunMin: 'Lộc Tồn',
  tianmaMin: 'Thiên Mã',
  qingyangMin: 'Kình Dương',
  tuoluoMin: 'Đà La',
  huoxingMin: 'Hỏa Tinh',
  lingxingMin: 'Linh Tinh',
  tiankuiMin: 'Thiên Khôi',
  tianyueMin: 'Thiên Việt',
  dikongMin: 'Địa Không',
  dijieMin: 'Địa Kiếp',
  tiankong: 'Thiên Không',
  tianxing: 'Thiên Hình',
  tianyao: 'Thiên Diêu',
  jieshen: 'Giải Thần',
  yinsha: 'Âm Sát',
  tianxi: 'Thiên Hỷ',
  tianguan: 'Thiên Quan',
  tianfu: 'Thiên Phúc',
  tianku: 'Thiên Khốc',
  tianxu: 'Thiên Hư',
  longchi: 'Long Trì',
  fengge: 'Phụng Các',
  hongluan: 'Hồng Loan',
  guchen: 'Cô Thần',
  guasu: 'Quả Tú',
  feilian: 'Phi Liêm',
  posui: 'Phá Toái',
  taifu: 'Đài Phụ',
  fenggao: 'Phong Cáo',
  tianwu: 'Thiên Vu',
  tianyue: 'Thiên Nguyệt',
  santai: 'Tam Thai',
  bazuo: 'Bát Tọa',
  engguang: 'Ân Quang',
  tiangui: 'Thiên Quý',
  tiancai: 'Thiên Tài',
  tianshou: 'Thiên Thọ',
  jiekong: 'Triệt Không',
  xunzhong: 'Tuần Trung',
  xunkong: 'Tuần Không',
  kongwang: 'Không Vong',
  jielu: 'Triệt Lộ',
  yuede: 'Nguyệt Đức',
  tianshang: 'Thiên Thương',
  tianshi: 'Thiên Sứ',
  tianchu: 'Thiên Trù',
  changsheng: 'Trường Sinh',
  muyu: 'Mục Dục',
  guandai: 'Quan Đới',
  linguan: 'Lâm Quan',
  diwang: 'Đế Vượng',
  shuai: 'Suy',
  bing: 'Bệnh',
  si: 'Tử',
  mu: 'Mộ',
  jue: 'Tuyệt',
  tai: 'Thai',
  yang: 'Dưỡng',
  boshi: 'Bác Sỹ',
  lishi: 'Lực Sỹ',
  qinglong: 'Thanh Long',
  xiaohao: 'Tiểu Hao',
  jiangjun: 'Tướng Quân',
  zhoushu: 'Tấu Thư',
  faylian: 'Phi Liêm',
  xishen: 'Hỷ Thần',
  bingfu: 'Bệnh Phù',
  dahao: 'Đại Hao',
  fubing: 'Phục Binh',
  guanfu: 'Quan Phủ',
  suijian: 'Tuế Kiện',
  huiqi: 'Hối Khí',
  sangmen: 'Tang Môn',
  guansuo: 'Quán Tác',
  gwanfu: 'Quan Phù',
  longde: 'Long Đức',
  baihu: 'Bạch Hổ',
  tiande: 'Thiên Đức',
  diaoke: 'Điếu Khách',
  jiangxing: 'Tướng Tinh',
  panan: 'Phan Án',
  suiyi: 'Tuế Dịch',
  xiishen: 'Tức Thần',
  huagai: 'Hoa Cái',
  jiesha: 'Kiếp Sát',
  zhaisha: 'Tai Sát',
  tiansha: 'Thiên Sát',
  zhibei: 'Chỉ Bối',
  xianchi: 'Hàm Trì',
  yuesha: 'Nguyệt Sát',
  wangshen: 'Vong Thần',
  yunkui: 'Vận Khôi',
  yunyue: 'Vận Việt',
  yunchang: 'Vận Xương',
  yunqu: 'Vận Khúc',
  yunluan: 'Vận Loan',
  yunxi: 'Vận Hỷ',
  yunlu: 'Vận Lộc',
  yunyang: 'Vận Dương',
  yuntuo: 'Vận Đà',
  yunma: 'Vận Mã',
  liukui: 'Lưu Khôi',
  liuyue: 'Lưu Việt',
  liuchang: 'Lưu Xương',
  liuqu: 'Lưu Khúc',
  liuluan: 'Lưu Loan',
  liuxi: 'Lưu Hỷ',
  liulu: 'Lưu Lộc',
  liuyang: 'Lưu Dương',
  liutuo: 'Lưu Đà',
  liuma: 'Lưu Mã',
  nianjie: 'Niên Giải',
} as const;
