export default {
  jiaHeavenly: '갑',
  yiHeavenly: '을',
  bingHeavenly: '병',
  dingHeavenly: '정',
  wuHeavenly: '무',
  jiHeavenly: '기',
  gengHeavenly: '경',
  xinHeavenly: '신',
  renHeavenly: '임',
  guiHeavenly: '계',
} as const;
