export default {
  jiaHeavenly: '甲',
  yiHeavenly: '乙',
  bingHeavenly: '丙',
  dingHeavenly: '丁',
  wuHeavenly: '戊',
  jiHeavenly: '己',
  gengHeavenly: '庚',
  xinHeavenly: '辛',
  renHeavenly: '壬',
  guiHeavenly: '癸',
} as const;
