export default {
  ziEarthly: 'zi',
  chouEarthly: 'chou',
  yinEarthly: 'yin',
  maoEarthly: 'mao',
  chenEarthly: 'chen',
  siEarthly: 'si',
  wuEarthly: 'woo',
  weiEarthly: 'wei',
  shenEarthly: 'shen',
  youEarthly: 'you',
  xuEarthly: 'xu',
  haiEarthly: 'hai',
} as const;
