export default {
  ziEarthly: '자',
  chouEarthly: '축',
  yinEarthly: '인',
  maoEarthly: '묘',
  chenEarthly: '진',
  siEarthly: '사',
  wuEarthly: '오',
  weiEarthly: '미',
  shenEarthly: '신',
  youEarthly: '유',
  xuEarthly: '술',
  haiEarthly: '해',
} as const;
